import React from 'react'
import { Layout } from '../layouts'
import { Seo } from '../components/seo'
import { pageTemplateStyle } from '../styles/page-template-style'
import { Flex } from 'baselift'
import { Content } from '../components/content'
import { useBreakpoint } from '../hooks/use-breakpoint'
import { useTheme } from 'emotion-theming'
import { Link } from 'gatsby'

const NotFoundPage = () => {
  const breakpointify = useBreakpoint()
  const theme = useTheme()

  return (
    <Layout>
      <Seo title="Not Found" description="HTTP 404 Not Found" />
      <Flex
        css={breakpointify({
          marginLeft: [0, 0, 0, theme.unit(5), 0],
          transition: 'margin-left 0.25s cubic-bezier(0.5, 0, 0.25, 1)',
        })}
      >
        <Content css={breakpointify(pageTemplateStyle)}>
          <h1>Page Not Found</h1>
          <p>This isn't the page you're looking for.</p>
          <p>
            If you're sure there's supposed to be a page here, <Link to="/support">Contact Support</Link>.<br />
          </p>
          <p>
            Or you can return to the <Link to="/">Home Page</Link> and we'll pretend this never happened.
          </p>
        </Content>
      </Flex>
    </Layout>
  )
}

export default NotFoundPage
